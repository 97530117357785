<template>
  <div class="video-item">
    <div class="box">
      <div class="img" @click="goDetails(it.id)">
        <img :src="it.videoThumbnail" alt="" />
        <div class="jing" v-if="it.boutiqueStatus == 1">精品</div>
        <div class="ft">
          <div class="l">
            <svg class="icon svg-icon" aria-hidden="true">
              <use xlink:href="#icon-dianzan1"></use>
            </svg>
            <div class="it">{{ it.loveNum }}</div>
            <svg class="icon svg-icon" aria-hidden="true">
              <use xlink:href="#icon-guankan"></use>
            </svg>
            <div class="it">
              {{ it.playNum }}
            </div>
          </div>
          <div class="r"></div>
        </div>
      </div>
      <div class="right">
        <div class="title">{{ it.videoTitle }}</div>
        <div class="ft">
          <div class="name">{{ it.userNickName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from '../../store';
export default {
  // name: "123",
  props: {
    it: {
      type: Object,
    },
    msg: {
      type: String,
      default: function () {
        return '';
      },
    },
  },
  data() {
    return {
      store: Store.state,
      i: {
        img: 'https://polyjetclub-backend.polysmartchain.com/images/normal/866.png',
        boutiqueStatus: 0, //	是否精品 1.是 0.否	integer
        createTime: '', //  创建时间	string
        id: '', //	视频id	integer
        loveNum: '', //  点赞量	integer
        playNum: '', //  播放量	integer
        updateTime: '', //	更新时间	string
        userNickName: '', //  用户昵称	string
        videoThumbnail: '', //	视频封面	string
        videoTitle: '', //  视频标题	string
      },
    };
  },
  methods: {
    goDetails(val) {
      this.store.actVideoId = val;
      this.$router.push('/mVideoDec');
    },
  },
};
</script>

<style lang="less" scoped>
.video-item {
  width: (174 / 3.75vw);
  margin-top: (10 / 3.75vw);
  // background-color: pink;
  .box {
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 2px 3px 0px rgba(176, 176, 176, 0.5);
    // background-color: aqua;
    .img {
      height: (100 / 3.75vw);
      overflow: hidden;
      position: relative;
      // display: flex;
      // justify-content: center;
      /* 矩形 8 */

      height: (102 / 3.75vw);
      border-radius: (6 / 3.75vw) (6 / 3.75vw) 0 0;
      background: rgba(0, 0, 0, 0.7);
      .jing {
        position: absolute;
        top: 0;
        z-index: 10;
        background-color: #ff7d41;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 12px;
        transform: rotate(45deg) translate(30%, -30px);
      }
      img {
        height: 100%;
        width: 100%;
        // position: absolute;
        // left: 50%;
        // transform: translateX(-50%);
        transform: scaleY(1.37);
      }

      .ft {
        position: absolute;
        bottom: (0 / 3.75vw);
        box-sizing: border-box;
        padding: 0 (5 / 3.75vw);
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: (8 / 3.75vw);
        color: #fff;
        /* 201 */
        font-size: (12 / 3.75vw);
        letter-spacing: 0em;
        background-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.534),
          rgba(0, 0, 0, 0.123)
        );
        .l {
          display: flex;
          align-items: center;
          .it {
            margin-right: 10px;
            margin-left: 5px;
          }
        }
      }
      .label {
        position: absolute;
        padding: (3 / 3.75vw) (5 / 3.75vw);
        text-align: center;
        display: inline-block;
        // background-color: pink;
        color: #fff;
        font-size: (10 / 3.75vw);
        z-index: 5;
      }
    }
    .right {
      padding: (9 / 3.75vw) (11 / 3.75vw);
      display: flex;
      flex-direction: column;
      .title {
        font-size: (12 / 3.75vw);
        // font-weight: bold;
        line-height: 16px;
        margin-bottom: (2 / 3.75vw);
        color: #333333;
        height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;

        display: -webkit-box;

        -webkit-box-orient: vertical;

        -webkit-line-clamp: 2;
      }
      .p {
        font-size: (12 / 3.75vw);
        line-height: (20 / 3.75vw);
        color: #999999;

        flex: 1;
        /* 这是视频的一段描述这是视频的一段描述这是视频的 */

        height: (47 / 3.75vw);
        font-family: PingFang SC Medium;
        font-weight: normal;
        line-height: (20 / 3.75vw);
        letter-spacing: 0em;
        color: #999999;
      }
      .ft {
        align-content: flex-end;
        font-size: (12 / 3.75vw);
        display: flex;
        align-items: center;
        .avatar {
          width: (20 / 3.75vw);
          height: (20 / 3.75vw);
          border-radius: 50%;
          margin-right: (10 / 3.75vw);
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .van-button {
      height: (100 / 3.75vw) !important;
      // height: 100% !important;
    }
  }
}
</style>
