<template>
  <div class="lunbo">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in list" :key="index">
        <img :src="list.imageUrl" alt="" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import Store from '../../store';
export default {
  components: {},
  mounted() {},
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      list: [
        {
          imageUrl:
            'https://polyjetclub-backend.polysmartchain.com/images/normal/300.png',
        },
        {
          imageUrl:
            'https://polyjetclub-backend.polysmartchain.com/images/normal/200.png',
        },
        {
          imageUrl:
            'https://polyjetclub-backend.polysmartchain.com/images/normal/100.png',
        },
      ],
    };
  },
  methods: {
    async getDate() {
      let { data: res } = await this.$http.get('/advertisement/list');
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      console.log(res.data);
      this.list = res.data;
    },
  },
};
</script>

<style lang="less">
.van-swipe {
  margin: 0 (18 / 3.75vw);
  // background-color: #0009;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: (20 / 3.75vw);
  height: (211 / 3.75vw);
  text-align: center;
  // background-color: #39a9ed;
  background-color: #0001;
  img {
    height: (211 / 3.75vw);
  }
}

.van-swipe__indicators {
  left: auto;
  transform: none;
  right: (15 / 3.75vw);
}
.van-swipe__indicator--active {
  background-color: #ff7d41 !important;
}
</style>
