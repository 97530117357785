<template>
  <div class="m-home">
    <div class="home">
      <div class="serchBox" v-if="false">
        <input type="text" placeholder="输入搜索内容" />
        <i class="el-icon-search"></i>
      </div>
      <home-lb v-if="false"></home-lb>
      <home-box-2></home-box-2>
    </div>
  </div>
</template>

<script>
import Store from '../../store';
import HomeBox2 from './home-box2.vue';
import HomeLb from './home-lb.vue';
export default {
  components: { HomeLb, HomeBox2 },
  mounted() {
    this.getVideoList();
  },
  data() {
    return {
      ifShow: false,
      store: Store.state,
      uti: Store.uti,

      loading: false,
      finished: false,
      act: '',
      list: [],
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    actItem(val) {
      this.act = val;
    },

    // 获取视频列表
    async getVideoList() {
      let { data: res } = await this.$http.post('/videoInfo/topicVideos', {
        pageNum: 1,
        pageSize: 100,
        topicId: 1,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.list = this.uti.addArr(this.list, res.data.records);
      console.log(this.list);
    },

    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        this.getVideoList();

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less">
.m-home {
  .serchBox {
    /* 矩形 6 */
    height: (36 / 3.75vw);
    margin: 0 (18 / 3.75vw) (10 / 3.75vw);

    border-radius: (18 / 3.75vw);
    // border: none;
    background: #ffffff;
    box-shadow: 0 0 (3 / 3.75vw) 0 rgba(178, 178, 178, 0.5);
    padding: 0 (18 / 3.75vw);
    display: flex;
    align-items: center;
    input {
      flex: 1;
      width: (1 / 3.75vw);
      font-size: (12 / 3.75vw);
      border: none;
    }
  }
}
</style>
