<template>
  <div class="html-box">
    <el-tabs v-if="false" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="综合" name="first0"> </el-tab-pane>
      <el-tab-pane label="精品" name="first"> </el-tab-pane>
      <!-- <el-tab-pane label="操作教程" name="second"> </el-tab-pane>
      <el-tab-pane label="体系介绍" name="third"> </el-tab-pane>
      <el-tab-pane label="其他" name="fourth"> </el-tab-pane> -->
      <el-tab-pane
        v-for="(item, index) in store.topics"
        :key="index"
        :label="item.title"
        :name="item.id + ''"
      >
      </el-tab-pane>
    </el-tabs>
    <div class="actListBox">
      <div class="actList" :class="{ downType: downType }">
        <div
          @click="actItem('first0')"
          :class="{ act: act == 'first0' }"
          class="item"
        >
          综合
        </div>
        <div
          @click="actItem('first')"
          :class="{ act: act == 'first' }"
          class="item"
        >
          精品
        </div>
        <div
          @click="actItem(item.id)"
          :class="{ act: act == item.id }"
          class="item"
          v-for="(item, index) in store.topics"
          :key="index"
          :label="item.title"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="downBut" @click="downType = !downType">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-fenlei"></use>
        </svg>
      </div>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad()"
      class="act-van-list"
    >
      <video-box
        :it="item"
        v-for="(item, index) in list"
        :key="index"
      ></video-box>
    </van-list>
  </div>
</template>
<script>
import Store from '../../store';
import videoBox from './video-box.vue';
export default {
  components: { videoBox },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      loading: false,
      finished: false,
      act: 'first0',
      activeName: 'first0',
      list: [],
      pageNum: 1,
      pageSize: 2,
      total: 0,

      // 下拉分类选择
      downType: false,
    };
  },
  mounted() {
    this.loading = true;
    this.onLoad();
  },
  methods: {
    actItem(val) {
      this.act = val;
      this.activeName = val;
      this.pageNum = 1;
      this.list = [];
      this.finished = false;
      this.loading = true;
      // 如果是精品
      this.onLoad();
    },
    // 选择某一个选项
    handleClick(tab) {
      // console.log(tab.index);
      this.list = [];
      this.finished = false;
      this.pageNum = 1;
      // 如果是精品
      if (tab.index == 0) {
        // this.act = this.store.topics[tab.index - 1].id;
        // this.getZHVideoList();
      } else if (tab.index == 1) {
        // this.act = this.store.topics[tab.index - 1].id;
        // this.getJVideoList();
      } else {
        this.act = this.store.topics[tab.index - 2].id;
        // this.getVideoList();
      }
      this.loading = true;
      this.onLoad();
    },

    // 获取视频分类
    async getVideoStateList() {
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.get(`/profileTopic/topics/${1}`, {});
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      console.log(res.data);
      this.store.topics = res.data;
    },
    // 获取视频列表
    async getVideoList() {
      let { data: res } = await this.$http.post('/videoInfo/topicVideos', {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        topicId: this.act,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.loading = false;
      this.total = Number(res.data.total);
      // this.list = res.data.records;
      this.list = this.uti.addArr(this.list, res.data.records);
      // 如果超出就
      if (this.pageNum * this.pageSize >= this.total) {
        this.finished = true;
      } else {
        this.pageNum++;
      }
    },
    // 获取精品视频列表
    async getJVideoList() {
      let { data: res } = await this.$http.post('/videoInfo/boutiqueVideos', {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.loading = false;
      this.total = Number(res.data.total);
      // this.list = res.data.records;
      this.list = this.uti.addArr(this.list, res.data.records);
      // 如果超出就
      if (this.pageNum * this.pageSize >= this.total) {
        this.finished = true;
      } else {
        this.pageNum++;
      }
    },
    // 获取综合视频列表
    async getZHVideoList() {
      let { data: res } = await this.$http.post('/videoInfo/allVideos', {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.loading = false;
      this.total = Number(res.data.total);
      // this.list = res.data.records;
      this.list = this.uti.addArr(this.list, res.data.records);
      // 如果超出就
      if (this.pageNum * this.pageSize >= this.total) {
        this.finished = true;
      } else {
        this.pageNum++;
      }
    },

    async onLoad() {
      // 如果是精品
      if (this.activeName == 'first0') {
        // this.act = this.store.topics[tab.index - 1].id;
        await this.getZHVideoList();
      } else if (this.activeName == 'first') {
        // this.act = this.store.topics[tab.index - 1].id;
        await this.getJVideoList();
      } else {
        await this.getVideoList();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.actListBox {
  display: flex;
  padding: (10 / 3.75vw) (10 / 3.75vw) (20 / 3.75vw);
  min-height: (40 / 3.75vw);
  .actList {
    width: 1px;
    flex: 1;
    // display: flex;
    // overflow: hidden;
    overflow: auto;
    min-height: (30 / 3.75vw);
    // flex-wrap: wrap;
    white-space: nowrap;
    .item {
      margin-right: (30 / 3.75vw);
      height: (35 / 3.75vw);
      line-height: (35 / 3.75vw);
      flex: 0;
      // width: 150px;
      font-size: (14 / 3.75vw);
      display: inline-block;
      font-size: #999;
    }
    .act {
      color: #ff7d41;
      border-bottom: 2px solid #ff7d41;
      //
    }
  }
  .downType.actList {
    // flex-wrap: wrap;
    white-space: normal !important;
  }
  .downBut {
    margin-left: (10 / 3.75vw);
    font-size: (18 / 3.75vw);
    padding-top: (7.5 / 3.75vw);
  }
}
/deep/.act-van-list {
  background-color: #f1f1f1;
  margin-top: -(15 / 3.75vw);
  padding: 0 (10 / 3.75vw);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .van-list__finished-text {
    width: 100%;
  }
  .van-list__loading {
    width: 100%;
  }
}
</style>
